import { useEffect, useState } from 'react';
import { On, Off } from 'common/designSystem/iconComponents';

export const TdToggle = ({ onChangeModifier, status, selector = '' }: { onChangeModifier: () => void, status: boolean, selector: string }) => {
  const [enabled, setEnabled] = useState(status);
  useEffect(() => {
    setEnabled(status);
  }, [status]);
  const handleChange = () => {
    onChangeModifier();
    setEnabled(!enabled);
  };
  return (

    <button type="button" onClick={handleChange} data-cy={selector}>
      { enabled ? <On className="h-[60px] w-[60px]" data-cy="reminder-on" /> : <Off className="h-[60px] w-[60px]" data-cy="reminder-off" />}
    </button>
  );
};

export default TdToggle;
