/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgIdentity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#identity_svg__a)" />
    <path
      d="M19.792 42.75s-2.042 0-2.042-2.042c0-2.041 2.042-8.166 12.25-8.166s12.25 6.125 12.25 8.166c0 2.042-2.042 2.042-2.042 2.042H19.792ZM30 30.5a6.125 6.125 0 1 0 0-12.25 6.125 6.125 0 0 0 0 12.25Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="identity_svg__a"
        x1={8}
        y1={8.5}
        x2={45.5}
        y2={55.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2175C5" />
        <stop offset={1} stopColor="#4199FB" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgIdentity;
