/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state';
import { clearCredentials } from 'features/login/authSlice';
import { RootState } from 'common/store/store';
import { useLocation } from 'react-router-dom';
export const SessionHandler = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { token, persist } = useAppSelector((state: RootState) => state.auth);
  const clearSession = () => {
    dispatch(clearCredentials());
  };
  const bypassSessionCheckPaths = ['/login', '/mfa', '/verify', '/signup', '/'];
  useEffect(() => {
    const checkForExistingSession = sessionStorage.getItem('isSessionChecked');
    if (!checkForExistingSession) {
      sessionStorage.setItem('isSessionChecked', 'false');
    }
  }, []);
  useEffect(() => {
    const isSessionChecked = sessionStorage.getItem('isSessionChecked');
    const isBypassPath = bypassSessionCheckPaths.includes(location.pathname);
    if (location.pathname === '/login' && isSessionChecked === 'false') {
      if (token && !persist?.isPersist) {
        clearSession();
      }
      sessionStorage.setItem('isSessionChecked', 'true');
    }
    if (token && !persist?.isPersist && isSessionChecked === 'false' && !isBypassPath) {
      sessionStorage.setItem('isSessionChecked', 'true');
      clearSession();
    }
  }, [token, persist, location]);

  return (<></>);
};

export default SessionHandler;
