import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'common/hooks/state';
import { RootState } from 'common/store/store';
import { clearCredentials, setCredentials } from 'features/login/authSlice';
import { Loader } from 'common/designSystem';
import { useValidateTokenMutation } from './PersistLoginService';

export const PersistLogin = () => {
  const dispatch = useAppDispatch();
  const { token, user, persist } = useAppSelector((state: RootState) => state.auth);
  const [auth, setAuth] = useState(false);
  const [validateToken, response] = useValidateTokenMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }

    validateToken({ isPersist: true })
      .unwrap()
      .then((res) => {
        const { status, data } = res;
        const payload = {
          user: {
            id: data.id,
            first_name: data.first_name,
            email: data.email,
            last_name: data.last_name,
            documents_count: data.documents_count,
            has_subscribed: data.has_subscribed,
            trial_ends_at: data.trial_ends_at,
            subscription_ends_at: data.subscription_ends_at,
            plan_type: data.plan_type,
            subscription_cancelled: data.subscription_cancelled,
            business_id: data.business_id,
          },
          token: { token: data.token },
          persist: { isPersist: persist ? persist.isPersist : false },
        };
        dispatch(setCredentials(payload));
        setAuth(status);
      })
      .catch((error: any) => {
        const { status } = error;
        dispatch(clearCredentials());
        if (status === 401) {
          navigate('/login');
        }
      });
  }, []);

  return (auth ? <Outlet /> : <Loader />);
};

export default PersistLogin;
