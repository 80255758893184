/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgTravel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#travel_svg__a)" />
    <circle cx={30} cy={30} r={30} fill="url(#travel_svg__b)" />
    <path
      d="M18.856 31.865 17 33.721l6.494 2.783L26.28 43l1.856-1.856-.928-4.64 4.348-4.347 4.737 10.11 1.757-1.758-1.561-13.284 3.71-3.713a2.623 2.623 0 1 0-3.71-3.71l-3.81 3.809-13.284-1.563-1.66 1.661 10.03 4.816-4.268 4.27-4.64-.93Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="travel_svg__a"
        x1={8}
        y1={8.5}
        x2={45.5}
        y2={55.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2175C5" />
        <stop offset={1} stopColor="#4199FB" />
      </linearGradient>
      <linearGradient
        id="travel_svg__b"
        x1={7}
        y1={10.5}
        x2={45.5}
        y2={54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BB870" />
        <stop offset={1} stopColor="#36997B" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgTravel;
