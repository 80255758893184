import { InputProps } from 'common/designSystem/input/types';
import React, { useState, useEffect } from 'react';
import { EyeIcon } from '@heroicons/react/24/solid';
import { EyeSlashIcon, CheckIcon } from '@heroicons/react/20/solid';
import PasswordChecklist from 'react-password-checklist';
import { useAppDispatch, useAppSelector } from 'common/hooks/state';
import { setUserData } from 'common/store/appSlice';
import { RootState } from '../../store/store';

export const Password = ({
  labelText, id, register, error, placeholder, errorMessage, value = '', isTick = false, showPasswordRule = true, name = 'password',
}: InputProps) => {
  const [passwordType, setPasswordType] = useState('password');
  const [showTick, setShowTick] = useState(isTick);
  const user = useAppSelector((state: RootState) => state.app.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (value) {
      dispatch(setUserData({
        user: {
          ...user,
          password: value,
        },
      }));
    }
  }, [value]);

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const handleTick = (isMatchedCriteria: boolean) => {
    setShowTick(isMatchedCriteria);
  };

  return (
    <label htmlFor="password" className="block text-sm text-gray-700">
      {labelText}
      <div className="relative mt-1 mb-2">
        <input
          id={id}
          name={name}
          type={passwordType}
          {...register(name)}
          placeholder={placeholder}
          className={`block w-full h-14 appearance-none rounded-xl border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm outline-doqit-primary
                   focus:outline-none sm:text-sm ${error ? 'border border-red-400' : 'bg-input-bg'}`}
        />
        <span className="absolute inset-y-0 right-2 flex items-center h-14">
          <button type="button" className="btn btn-outline-primary" onClick={togglePassword}>
            {passwordType === 'password' ? (<EyeIcon className="h-6 w-6 text-gray-500" />)
              : (<EyeSlashIcon className="h-6 w-6 text-gray-500" />) }
          </button>
          {(showTick || isTick) && <CheckIcon className="h-6 w-6 text-gray-500" />}
        </span>
        { error && (
          <p className="text-error text-sm mt-2 flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span className={`text-sm pl-1 ${name}-error`}>{errorMessage && errorMessage}</span>
          </p>
        )}
      </div>
      {((showPasswordRule && value)) && (
        <>
          <span className="font-semibold pb-0.5">Password must meet following requirements</span>
          <PasswordChecklist
            rules={['minLength', 'number', 'capital', 'specialChar']}
            minLength={12}
            value={value}
            messages={{
              minLength: 'Minimum 12 characters required',
              number: 'At least 1 number required',
              capital: 'At least 1 capital letter',
              specialChar: 'At least 1 special symbol i.e. ! @ # $ % ^ & * ( ) _ - = + [ ] { }',
            }}
            onChange={(isValid) => handleTick(isValid)}
          />
        </>
      )}
    </label>
  );
};

export default Password;
