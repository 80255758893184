/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgWork = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#work_svg__a)" />
    <path
      d="M26 23h8v-2h-8v2Zm-5.5 0v20h-1c-.958 0-1.781-.344-2.469-1.031C16.344 41.28 16 40.459 16 39.5v-13c0-.958.344-1.781 1.031-2.469C17.72 23.344 18.541 23 19.5 23h1ZM38 23v20H22V23h2v-2.5c0-.417.146-.77.438-1.063A1.446 1.446 0 0 1 25.5 19h9c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062V23h2Zm6 3.5v13c0 .958-.344 1.781-1.031 2.469C42.28 42.656 41.459 43 40.5 43h-1V23h1c.958 0 1.781.344 2.469 1.031.687.688 1.031 1.51 1.031 2.469Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="work_svg__a"
        x1={8}
        y1={8.5}
        x2={45.5}
        y2={55.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A6FDD" />
        <stop offset={1} stopColor="#835EEA" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgWork;
