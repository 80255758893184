/* eslint-disable @typescript-eslint/naming-convention */
import runtimeEnv from '@mars/heroku-js-runtime-env';

const { REACT_APP_API_URL, REACT_APP_MIXPANEL_TOKEN } = runtimeEnv();
export const apiRootUrl = `${REACT_APP_API_URL}api/v1`;
export const mixpanelToken = REACT_APP_MIXPANEL_TOKEN;
// RTK Query

export const signInUrl = '/signin';
export const signUpUrl = '/sign-up';
export const verifyUrl = '/verify-mail-otp';
export const resendVerificationOtp = '/resend-verification-mail/%userId%';
export const resetPasswordLink = '/forgot-password';
export const resetPassword = '/reset-password';
export const validateToken = '/validate-token';
export const categoryUrl = '/categories';
export const searchDocumentUrl = '/document/search/%searchQuery%';
export const preSignedUrl = '/generate-presigned-post-url';
export const documents = '/documents';
export const viewCategoryDocuments = '/category/%categoryId%/documents';
export const deleteS3File = '/delete-s3-file';
export const deleteS3FileById = '/document-file/%docFileId%';
export const vehicleDvla = '/vehicles';
export const pinDocuments = '/document/%documentId%/pin';
export const dashboardCategories = '/categories';
export const dashboardCategoriesCount = '/categories-docs-count';
export const deleteDocument = 'document/%documentId%/delete';
export const editDocumentDetails = 'document/%documentId%/details';
export const updateDocument = 'document/%documentId%/update';
export const upcomingReminders = '/reminders/time';
export const doneReminders = '/reminders/done';
export const deleteReminder = '/reminders/%reminderId%';
export const markAsDoneReminders = '/reminders/%reminderId%/done';
export const remindLaterReminders = '/reminders/%reminderId%/later';
export const homeReminders = '/reminders/home';
export const logout = '/logout';
export const updateUserProfile = '/user/%userId%/update';
export const faq = '/faqs';
export const deleteUser = '/user';
export const vehicleList = '/vehicles';
export const deleteVehicle = '/vehicles/%id%';
export const verifyMfaOtp = '/verify-mfa-otp';
export const cancelSubscription = '/subscriptions/cancel';
