/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgFamily = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#family_svg__a)" />
    <path
      d="M22.026 19.627a2.322 2.322 0 0 0-1.732 2.251 2.32 2.32 0 0 0 2.922 2.247c1.137-.295 1.91-1.485 1.695-2.622-.247-1.367-1.577-2.225-2.885-1.877ZM36.846 19.6c-.633.16-1.276.675-1.55 1.244-.788 1.613.467 3.484 2.236 3.35a2.34 2.34 0 0 0 2.016-1.468c.177-.445.177-1.25 0-1.695a2.428 2.428 0 0 0-1.346-1.33c-.338-.128-1.035-.182-1.356-.102ZM20.74 24.64c-.751.085-1.314.456-1.834 1.2-.467.676-2.58 5.003-2.622 5.368a.978.978 0 0 0 .434.873c.316.21.75.204 1.061-.015.204-.145.327-.36 1.357-2.349l1.131-2.182.027 6.096c.027 6.026.027 6.102.134 6.29.065.101.215.257.343.348.199.14.28.16.569.16.29 0 .37-.021.568-.16.123-.091.284-.268.354-.397l.123-.236v-6.369l.23.016.226.016.027 3.217c.032 3.55.01 3.389.37 3.689a1.049 1.049 0 0 0 1.565-.268l.129-.204.016-6.112.016-6.112.858 1.737.852 1.742.204-.343c.258-.44.719-.944.998-1.083.123-.059.225-.113.236-.123.027-.022-1.12-2.466-1.383-2.944-.564-1.013-1.084-1.539-1.77-1.77-.262-.09-.504-.101-2.064-.117-.97-.006-1.947.005-2.156.032ZM35.462 24.64c-.863.102-1.464.584-2.101 1.689-.236.402-1.486 2.997-1.486 3.077 0 .022.113.091.247.155.327.162.735.553.97.933.108.172.199.311.21.311.005 0 .391-.723.852-1.608.467-.89.853-1.609.858-1.598.01.01-.209 1.126-.482 2.483l-.5 2.46.323.644c.22.44.327.724.348.911.054.51-.16.976-.547 1.196l-.214.123H35.039v4.161l.14.279c.224.45.712.676 1.184.552a1.06 1.06 0 0 0 .66-.547c.102-.193.107-.327.107-2.327v-2.118h.477l.016 2.156.016 2.16.129.204a1.04 1.04 0 0 0 1.828-.075c.102-.193.108-.327.108-2.327v-2.118h.804c.729 0 .804-.01.804-.091 0-.054-.349-1.796-.778-3.871-.428-2.075-.772-3.78-.76-3.79.01-.011.514.938 1.12 2.112 1.002 1.94 1.12 2.145 1.324 2.29a.95.95 0 0 0 1.394-.36c.14-.295.145-.499.01-.831-.257-.643-2.273-4.665-2.53-5.03-.525-.766-1.072-1.114-1.876-1.205-.526-.06-3.233-.06-3.754 0ZM29.532 26.618a1.546 1.546 0 0 0-1.04 1.77c.236 1.195 1.727 1.662 2.595.82a1.553 1.553 0 0 0-.638-2.617c-.332-.096-.574-.09-.917.027Z"
      fill="#fff"
    />
    <path
      d="M28.578 29.942c-.306.08-.6.268-.858.552-.348.387-1.92 3.55-1.92 3.866 0 .53.65.831 1.041.477.102-.091.424-.654.88-1.539l.723-1.394v3.957c0 3.228.016 3.99.075 4.129.247.578.997.621 1.265.07.102-.215.108-.323.108-2.301v-2.075h.268v2.096c0 1.85.01 2.118.091 2.285.247.52.965.514 1.265-.011.049-.075.07-1.217.092-4.102l.026-3.994.236.456c1.062 2.064 1.25 2.396 1.394 2.482.295.172.697.08.885-.198.182-.285.128-.461-.66-2.08-.954-1.968-1.158-2.269-1.742-2.58-.21-.112-.29-.117-1.614-.128-.767-.005-1.464.01-1.555.032Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="family_svg__a"
        x1={7}
        y1={10.5}
        x2={45.5}
        y2={54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7FD3BA" />
        <stop offset={1} stopColor="#9DE161" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgFamily;
