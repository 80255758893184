import * as React from 'react';
import { SVGProps } from 'react';
const SvgOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={46} height={22} rx={11} fill="#fff" />
    <circle cx={12} cy={12} r={6} fill="#180064" />
    <rect
      x={1}
      y={1}
      width={46}
      height={22}
      rx={11}
      stroke="#180064"
      strokeWidth={2}
    />
  </svg>
);
export default SvgOff;
