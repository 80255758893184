import * as React from 'react';
const SvgCheckmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 65 64"
    strokeWidth={4}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M53.8327 16L24.4993 45.3333L11.166 32"
    />
  </svg>

);
export default SvgCheckmark;
