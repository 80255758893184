/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgHealth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={24} cy={24} r={24} fill="url(#medical_svg__a)" />
    <path
      d="m24 35-1.595-1.51C16.74 28.144 13 24.608 13 20.293 13 16.758 15.662 14 19.05 14c1.914 0 3.751.927 4.95 2.38 1.199-1.453 3.036-2.38 4.95-2.38 3.388 0 6.05 2.758 6.05 6.294 0 4.315-3.74 7.851-9.405 13.195L24 35Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="medical_svg__a"
        x1={6.4}
        y1={6.8}
        x2={36.4}
        y2={44.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C52121" />
        <stop offset={1} stopColor="#F09191" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgHealth;
