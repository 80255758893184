/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgPension = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={24.5} cy={24} r={24} fill="url(#pension_svg__a)" />
    <g clipPath="url(#pension_svg__b)">
      <g clipPath="url(#pension_svg__c)">
        <g clipPath="url(#pension_svg__d)" fill="#fff">
          <path d="M31.722 17.507a10.135 10.135 0 0 0-6.216-2.943.369.369 0 0 1-.314-.256.72.72 0 0 0-1.384 0 .37.37 0 0 1-.314.256 10.214 10.214 0 0 0-9.208 10.144.748.748 0 0 0 .33.632.73.73 0 0 0 .915-.095 2.366 2.366 0 0 1 3.343-.002.728.728 0 0 0 1.03.007 2.854 2.854 0 0 1 3.716-.268.377.377 0 0 1 .15.3v6.723c0 .392-.3.73-.692.75a.729.729 0 0 1-.767-.73.73.73 0 0 0-.743-.729c-.41.007-.725.37-.716.78a2.192 2.192 0 0 0 2.16 2.139c1.226.015 2.218-1 2.218-2.226v-6.707a.375.375 0 0 1 .15-.3 2.855 2.855 0 0 1 3.711.264.73.73 0 0 0 1.032 0 2.367 2.367 0 0 1 3.346 0 .73.73 0 0 0 .89.11.747.747 0 0 0 .355-.649 10.148 10.148 0 0 0-2.992-7.2Z" />
          <path d="M31.572 32.542c0 .055-.049.1-.108.1H27.78c-.059 0-.107-.045-.107-.1v-.486c0-.046.033-.087.082-.098.592-.138.98-.618.98-1.188 0-.186-.034-.371-.08-.558h-.904c-.06 0-.108-.045-.108-.1v-.379c0-.056.049-.1.108-.1h.722c-.105-.317-.196-.64-.196-.973 0-.956.788-1.517 2.038-1.517.357 0 .692.069.946.16.042.015.07.053.07.094v.498a.095.095 0 0 1-.012.047.102.102 0 0 1-.033.036.113.113 0 0 1-.098.013 2.602 2.602 0 0 0-.812-.132c-.718 0-1.175.335-1.175.885 0 .312.084.604.18.89h1.507c.06 0 .108.045.108.1v.379c0 .055-.049.1-.108.1h-1.326c.041.167.07.34.07.518 0 .513-.221.932-.59 1.149v.059h2.422c.06 0 .108.045.108.1v.503Z" />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="pension_svg__b">
        <path fill="#fff" transform="translate(13.5 13)" d="M0 0h22v22H0z" />
      </clipPath>
      <clipPath id="pension_svg__c">
        <path fill="#fff" transform="translate(13.5 13)" d="M0 0h22v22H0z" />
      </clipPath>
      <clipPath id="pension_svg__d">
        <path fill="#fff" transform="translate(13.5 13)" d="M0 0h22v22H0z" />
      </clipPath>
      <linearGradient
        id="pension_svg__a"
        x1={6.1}
        y1={7.6}
        x2={38.5}
        y2={42.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AAB7" />
        <stop offset={1} stopColor="#14BEBC" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgPension;
