/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#education_svg__a)" />
    <g clipPath="url(#education_svg__b)">
      <path
        d="M30 21c-.354 0-.704.061-1.037.18l-12.272 4.429a1.053 1.053 0 0 0 0 1.976l2.533.914a7.195 7.195 0 0 0-1.124 3.865v1.229c0 1.241-.473 2.523-.976 3.533a11.586 11.586 0 0 1-.984 1.644.704.704 0 0 0 .39 1.101l2.8.7a.698.698 0 0 0 .853-.542c.376-1.872.188-3.55-.092-4.753a9.668 9.668 0 0 0-.591-1.836v-1.076c0-1.32.446-2.567 1.22-3.563a5.341 5.341 0 0 1 2.153-1.561l6.869-2.698a.7.7 0 0 1 .512 1.298l-6.869 2.698a4.016 4.016 0 0 0-1.409.945L28.96 32a3.098 3.098 0 0 0 2.073 0l12.277-4.416a1.053 1.053 0 0 0 0-1.976l-12.272-4.43A3.096 3.096 0 0 0 30 21Zm-8.4 16.44c0 1.544 3.762 3.149 8.4 3.149s8.4-1.605 8.4-3.149l-.67-6.357-6.22 2.247a4.458 4.458 0 0 1-3.02 0l-6.22-2.247-.67 6.357Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="education_svg__a"
        x1={10}
        y1={9.5}
        x2={49.5}
        y2={54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9609E" />
        <stop offset={1} stopColor="#FFAB97" />
      </linearGradient>
      <clipPath id="education_svg__b">
        <path fill="#fff" transform="translate(16 17)" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEducation;
