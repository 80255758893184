import React from 'react';
import { CrossIcon, DownloadIcon } from 'common/designSystem/iconComponents';

export const PreviewImage = ({
  id, src, name, isPreview, onClickModifier, fileKey, fileUrl, isDownload = false,
}: {
    id?: number,
    src: string,
    name: string,
    isPreview: boolean,
    fileKey?: string,
    fileUrl?: string,
    isDownload?: boolean,
    onClickModifier?: (docFileId?: number, fileName?: string) => void
   }) => {
  const downloadFile = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  };
  return (
    <button type="button" className="preview flex flex-col text-center relative mr-3">
      { isPreview && (
        <CrossIcon
          className="absolute left-[53px] mt-[2px] h-[24px] w-[24px] text-white rounded-[50%] bg-doqit-primary"
          onClick={() => onClickModifier && onClickModifier(id, fileKey)}
        />
      )}
      { isDownload && (
        <DownloadIcon
          className="absolute left-auto mt-[2px] h-[24px] w-[24px] text-white rounded-[50%] bg-doqit-primary"
          onClick={() => downloadFile(fileUrl ? fileUrl : src, name)}
        />
      )}
      <img src={src} className={`${(isPreview) ? 'h-[80px] w-[80px]' : 'h-[80px] w-[80px]'} rounded-md`} alt="me-road" data-cy="file-preview" />
      <span className={`${(isPreview) ? 'ml-[14px]' : 'ml-[14px]'} text-xs text-doqit-primary max-w-[60px] break-words mt-[5px]`}>{name}</span>
    </button>
  );
};
