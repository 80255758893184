import { validateToken } from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { ValidateTokenRequest, ValidateTokenResponse } from './types';

export const validateTokenApi = baseApi
  .enhanceEndpoints({ addTagTypes: [] })
  .injectEndpoints({
    endpoints: (builder) => ({
      validateToken: builder.mutation<ValidateTokenResponse, ValidateTokenRequest>({
        query: () => ({
          url: validateToken,
          method: 'GET',
        }),
      }),
    }),
  });

export const {
  useValidateTokenMutation,
} = validateTokenApi;
