import React, { useEffect, useState, useRef } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import DatePicker from 'react-datepicker';
import { InfoErrorIcon } from 'common/designSystem/iconComponents';
import { useAppSelector } from 'common/hooks/state';
import 'react-datepicker/dist/react-datepicker.css';
import useOutsideClick from 'common/hooks/useOutSideClick';
import { TdDatePickerProp } from '../tdinput/types';
import { RootState } from '../../store/store';

export const TdDatePicker = ({
  onChangeDateHandler, error, errorMessage, placeholder, format, editRenewelDate, isRemindLaterDate, isEditable,
}: TdDatePickerProp) => {
  const componentRef = useRef();
  useOutsideClick(componentRef, () => {
    setIsOpen(false);
  });
  const uploadDocumentStore = useAppSelector((state: RootState) => state.app.uploadDocument);
  const { renewalDate } = uploadDocumentStore;
  let initialDate = renewalDate ? new Date(renewalDate) : null;
  if (isRemindLaterDate) {
    initialDate = new Date(isRemindLaterDate);
  }
  const [isDateEnable, setDateEnable] = useState<boolean | true >(true);
  const [startDate, setStartDate] = useState<Date | null>(initialDate);
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (editRenewelDate) {
      setStartDate(new Date(editRenewelDate));
      setDateEnable(true);
    }
    if (renewalDate) {
      setDateEnable(false);
    }
    setDateEnable(isEditable);
  }, [renewalDate, editRenewelDate]);

  const onDateChange = (date:Date) => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1000);
    setStartDate(date);
    if (onChangeDateHandler) {
      onChangeDateHandler(date);
    }
  };

  return (
    <div
      className="mt-1 relative"
      onClick={() => isDateEnable && setIsOpen(true)}
      onKeyDown={() => isDateEnable && setIsOpen(true)}
      ref={componentRef as any}
    >
      <CalendarIcon
        className="pointer-events-none w-6 h-6 absolute transform -translate-y-[8%] left-3 mt-[17px] z-10"
      />
      <DatePicker
        dateFormat={format}
        selected={startDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={(date: Date) => onDateChange(date)}
        minDate={new Date()}
        placeholderText={placeholder}
        className={`block w-full h-14 appearance-none rounded-xl 
              border border-gray-300 px-12 py-2 placeholder-gray-400 shadow-sm outline-doqit-primary
              focus:outline-none sm:text-sm ${error ? 'border border-red-400' : 'bg-input-bg'}`}
        data-cy="calendar"
        open={isOpen}
        readOnly={true}
      />
      { error && (
        <p className="text-error text-sm mt-2 flex flex-row">
          <InfoErrorIcon />
          <span className="text-sm pl-1 renewal_date-error">{errorMessage && errorMessage}</span>
        </p>
      )}
    </div>
  );
};
