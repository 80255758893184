import { SVGProps } from 'react';
import { Category } from 'common/type';
import {
  Bank,
  Family,
  Identity,
  Pension,
  Property,
  Subscriptions,
  Tax,
  Vehicle,
  Education,
  Health,
  Travel,
  Work,
} from './designSystem/iconComponents';

type CatergoryLogoMap = {
  [key: string]: (props: SVGProps<SVGSVGElement>) => JSX.Element
}
type IdToCategoryNameMap = {
  [key: number]: string;
}
export const mobileWidthLimit = 667;
export const tabletWidthLimit = 768;
export const fileUploadSize = 20971520;
export const vehicleId = 11;
export const linkPath = {
  documentTitle: '/document',
  documentName: '/document/name',
  documentUpload: '/document/upload',
  documentUploadDvla: '/document/dvla',
  documentVehicle: '/document/vehicle',
  documentRenewal: '/document/renewal',
  documentReview: '/document/review',
  documentSuccess: '/document/success',
  documentList: '/documents',
  searchDocumentPath: '/searchDocument',
  dashboard: '/dashboard',
  reminder: '/reminders',
  menuBar: '/menu',
  tutorial: '/tutorial',
  verify: '/verify',
  success: '/success',
  forgotPassword: '/forgot-password',
  resetPasword: '/reset-password/:token',
  login: '/login',
  signup: '/signup',
  terms: '/terms-and-condition',
  category: '/documents/viewCategory/:categoryId',
  editDocuments: '/editDocuments/:documentId',
  dashboardCategory: '/documents/viewCategory',
  editDocumentsID: '/editDocuments',
  editCategory: '/editDocuments/category',
  userProfile: '/myProfile',
  privacyPolicy: '/privacyPolicy',
  aboutDoqit: '/aboutDoqit',
  faq: '/faq',
  vehicleList: '/vehicleList/:vehicleCategoryId',
  vehicleListPath: '/vehicleList',
  mfa: '/mfa',
  pricing: '/pricing',
};

export const categoryIcons: CatergoryLogoMap = {
  Bank,
  Family,
  Identity,
  Pension,
  Property,
  Subscriptions,
  Tax,
  Vehicle,
  Education,
  Health,
  Travel,
  Work,
};

export const initialCategoryState: Category[] = [
  {
    id: 1,
    name: 'Bank',
    slug: 'bank',
    image: '',
  },
  {
    id: 2,
    name: 'Education',
    slug: 'education',
    image: '',
  },
  {
    id: 3,
    name: 'Family',
    slug: 'family',
    image: '',
  },
  {
    id: 5,
    name: 'Health',
    slug: 'health',
    image: '',
  },
  {
    id: 4,
    name: 'Identity',
    slug: 'identity',
    image: '',
  },
  {
    id: 6,
    name: 'Pension',
    slug: 'pension',
    image: '',
  },
  {
    id: 7,
    name: 'Property',
    slug: 'property',
    image: '',
  },
  {
    id: 9,
    name: 'Subscriptions',
    slug: 'subscriptions',
    image: '',
  },
  {
    id: 8,
    name: 'Tax',
    slug: 'tax',
    image: '',
  },
  {
    id: 10,
    name: 'Travel',
    slug: 'travel',
    image: '',
  },
  {
    id: 11,
    name: 'Vehicle',
    slug: 'vehicle',
    image: '',
  },
  {
    id: 12,
    name: 'Work',
    slug: 'work',
    image: '',
  },
];
export const categoryIdToNameMap: IdToCategoryNameMap = {
  1: 'Bank',
  2: 'Education',
  3: 'Family',
  5: 'Health',
  4: 'Identity',
  6: 'Pension',
  7: 'Property',
  9: 'Subscriptions',
  8: 'Tax',
  10: 'Travel',
  11: 'Vehicle',
  12: 'Work',
};
