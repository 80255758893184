import React, { memo, Suspense } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { lang } from 'common/lang';
import { linkPath } from 'common/constants';
import PersistLogin from 'features/PersistLogin/PersistLogin';
import { Loader, ScrollToTop } from 'common/designSystem';
import SessionHandler from 'features/sessionHandler';
const Welcome = React.lazy(() => import('../welcome'));
const Signup = React.lazy(() => import('../signup'));
const TermsPolicy = React.lazy(() => import('../TermsPolicy/TermsPolicy'));
const Verify = React.lazy(() => import('../verify'));
const Dashboard = React.lazy(() => import('../dashboard'));
const Pricing = React.lazy(() => import('../pricing'));
const Success = React.lazy(() => import('../success'));
const Login = React.lazy(() => import('../login'));
const ForgotPassword = React.lazy(() => import('../ForgotPassword'));
const ResetPassword = React.lazy(() => import('../ResetPassword'));
const Tutorial = React.lazy(() => import('../Tutorial'));
const UploadDocument = React.lazy(() => import('../UploadDocument'));
const RenewalDate = React.lazy(() => import('../UploadDocument/RenewalDate'));
const Documents = React.lazy(() => import('../Documents'));
const Reminders = React.lazy(() => import('../Reminders'));
const MenuBar = React.lazy(() => import('../MenuBar'));
const Upload = React.lazy(() => import('../UploadDocument/UploadFile'));
const UploadSuccess = React.lazy(() => import('../UploadDocument/UploadSuccess'));
const SearchDocument = React.lazy(() => import('../SearchDocument'));
const DocumentReview = React.lazy(() => import('../UploadDocument/DocumentReview'));
const UploadDVLA = React.lazy(() => import('../UploadDocument/UploadDVLA'));
const GiveItName = React.lazy(() => import('../UploadDocument/GiveItName'));
const VehicleDetails = React.lazy(() => import('../UploadDocument/VehicleDetails'));
const ViewCategory = React.lazy(() => import('../Documents/ViewCategory'));
const EditDocument = React.lazy(() => import('../Documents/EditDocument'));
const EditDocumentCategory = React.lazy(() => import('../Documents/EditCategory'));
const UserProfile = React.lazy(() => import('../MenuBar/UserProfile'));
const PrivacyPolicy = React.lazy(() => import('../MenuBar/PrivacyPolicy'));
const AboutDoqit = React.lazy(() => import('../MenuBar/AboutDoqit'));
const FAQ = React.lazy(() => import('../MenuBar/FAQ'));
const VehicleList = React.lazy(() => import('../Vehicle'));
const Mfa = React.lazy(() => import('../mfa'));

const WebRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <SessionHandler />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path={linkPath.signup} element={<Signup />} />
          <Route path={linkPath.terms} element={<TermsPolicy />} />
          <Route path={linkPath.login} element={<Login />} />
          <Route path={linkPath.editCategory} element={<EditDocumentCategory />} />
          <Route path={linkPath.documentList} element={<PersistLogin />}>
            <Route path={linkPath.documentList} element={<Documents />} />
          </Route>
          <Route path={linkPath.reminder} element={<PersistLogin />}>
            <Route path={linkPath.reminder} element={<Reminders />} />
          </Route>
          <Route path={linkPath.menuBar} element={<PersistLogin />}>
            <Route path={linkPath.menuBar} element={<MenuBar />} />
          </Route>
          <Route path={linkPath.dashboard} element={<PersistLogin />}>
            <Route path={linkPath.dashboard} element={<Dashboard />} />
          </Route>
          <Route path={linkPath.pricing} element={<PersistLogin />}>
            <Route path={linkPath.pricing} element={<Pricing />} />
          </Route>
          <Route path={linkPath.documentTitle} element={<PersistLogin />}>
            <Route path={linkPath.documentTitle} element={<UploadDocument />} />
          </Route>
          <Route path={linkPath.documentName} element={<PersistLogin />}>
            <Route path={linkPath.documentName} element={<GiveItName />} />
          </Route>
          <Route path={linkPath.documentUploadDvla} element={<PersistLogin />}>
            <Route path={linkPath.documentUploadDvla} element={<UploadDVLA />} />
          </Route>
          <Route path={linkPath.documentVehicle} element={<PersistLogin />}>
            <Route path={linkPath.documentVehicle} element={<VehicleDetails />} />
          </Route>
          <Route path={linkPath.documentRenewal} element={<PersistLogin />}>
            <Route path={linkPath.documentRenewal} element={<RenewalDate />} />
          </Route>
          <Route path={linkPath.searchDocumentPath} element={<PersistLogin />}>
            <Route path={linkPath.searchDocumentPath} element={<SearchDocument />} />
          </Route>
          <Route path={linkPath.documentSuccess} element={<PersistLogin />}>
            <Route path={linkPath.documentSuccess} element={<UploadSuccess />} />
          </Route>
          <Route path={linkPath.documentUpload} element={<PersistLogin />}>
            <Route path={linkPath.documentUpload} element={<Upload />} />
          </Route>
          <Route path={linkPath.documentReview} element={<PersistLogin />}>
            <Route path={linkPath.documentReview} element={<DocumentReview />} />
          </Route>
          <Route path={linkPath.category} element={<PersistLogin />}>
            <Route path={linkPath.category} element={<ViewCategory />} />
          </Route>
          <Route path={linkPath.editDocuments} element={<PersistLogin />}>
            <Route path={linkPath.editDocuments} element={<EditDocument />} />
          </Route>
          <Route path={linkPath.userProfile} element={<PersistLogin />}>
            <Route path={linkPath.userProfile} element={<UserProfile />} />
          </Route>
          <Route path={linkPath.aboutDoqit} element={<PersistLogin />}>
            <Route path={linkPath.aboutDoqit} element={<AboutDoqit />} />
          </Route>
          <Route path={linkPath.faq} element={<PersistLogin />}>
            <Route path={linkPath.faq} element={<FAQ />} />
          </Route>
          <Route path={linkPath.vehicleList} element={<PersistLogin />}>
            <Route path={linkPath.vehicleList} element={<VehicleList />} />
          </Route>
          <Route path={linkPath.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={linkPath.tutorial} element={<Tutorial />} />
          <Route path={linkPath.verify} element={<Verify />} />
          <Route path={linkPath.success} element={<Success />} />
          <Route path={linkPath.forgotPassword} element={<ForgotPassword />} />
          <Route path={linkPath.resetPasword} element={<ResetPassword />} />
          <Route path={linkPath.mfa} element={<Mfa />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default memo(WebRoutes);
