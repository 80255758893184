import React from 'react';
const progressBarColor = ['bg-progress-active', 'bg-doqit-primary', 'bg-progress'];
export const ProgressBar = ({
  stepCategory, stepName, stepRenewal, stepFile,
}: { stepCategory: number, stepName: number, stepRenewal: number, stepFile: number}) => {
  return (
    <div className="flex">
      <div className={`progress-bar ${(progressBarColor[stepCategory])}`}></div>
      <div className={`progress-bar ${(progressBarColor[stepName])}`}></div>
      <div className={`progress-bar ${(progressBarColor[stepRenewal])}`}></div>
      <div className={`progress-bar ${(progressBarColor[stepFile])}`}></div>
    </div>
  );
};
export default ProgressBar;
