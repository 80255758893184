/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#bank_svg__a)" />
    <path
      d="M20.236 25.459h19.528c.426 0 .813-.269.961-.669.148-.4.031-.855-.292-1.134L30.67 15.25a1.025 1.025 0 0 0-1.338 0l-9.764 8.407a1.03 1.03 0 0 0-.292 1.134c.148.4.535.669.961.669ZM39.764 39.355H20.236c-.565 0-1.025.46-1.025 1.026v1.594c0 .565.46 1.025 1.025 1.025h19.528c.565 0 1.025-.46 1.025-1.025V40.38c0-.566-.46-1.026-1.025-1.026ZM20.855 26.823a.267.267 0 0 0-.267.267v10.153c0 .147.12.267.267.267h3.365c.147 0 .267-.12.267-.267V27.09a.267.267 0 0 0-.267-.267h-3.365ZM28.317 26.823a.267.267 0 0 0-.267.267v10.153c0 .147.12.267.267.267h3.366c.147 0 .266-.12.266-.267V27.09a.267.267 0 0 0-.266-.267h-3.366ZM35.78 26.823a.267.267 0 0 0-.267.267v10.153c0 .147.12.267.267.267h3.365c.147 0 .267-.12.267-.267V27.09a.267.267 0 0 0-.267-.267H35.78Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="bank_svg__a"
        x1={7}
        y1={10.5}
        x2={45.5}
        y2={54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1B04D" />
        <stop offset={1} stopColor="#E69185" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgBank;
