/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgDoqitLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 362 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#doqitLogo_svg__a)" fill="#00164C">
      <path d="M123.278 33.808c-21.875 0-38.91 15.499-38.91 36.32 0 21.136 17.035 36.325 38.91 36.325 21.701 0 38.905-15.19 38.905-36.325 0-20.821-17.204-36.32-38.905-36.32Zm0 55.732c-8.751 0-15.158-6.572-15.158-19.412 0-12.678 6.25-19.413 15.158-19.413s15.153 6.735 15.153 19.413c0 12.84-6.25 19.412-15.153 19.412ZM245.617 34.672h-22.346l-.624 11.177c-5.783-8.154-15.939-11.416-23.752-11.416-18.12 0-31.255 14.716-31.255 35.695 0 20.826 13.124 35.699 31.244 35.699 7.661 0 16.564-2.799 22.504-9.866V141h24.219l11.328-19.772-11.318-.054V34.672Zm-38.595 53.939c-8.593 0-15.158-6.73-15.158-18.483 0-11.754 6.565-18.484 15.158-18.484 8.594 0 15.158 6.736 15.158 18.484 0 11.747-6.564 18.483-15.158 18.483ZM283.426 34.683h-24.218v69.264h24.218V34.683ZM258.285 12.775a12.81 12.81 0 0 0 1.491 6.024 12.737 12.737 0 0 0 16.102 5.823 12.751 12.751 0 0 0 4.986-3.681 12.786 12.786 0 0 0 2.384-11.8 12.782 12.782 0 0 0-3.164-5.336h-18.099a12.749 12.749 0 0 0-3.7 8.97Z" />
      <path d="M271.035 0a12.697 12.697 0 0 0-9.05 3.805h18.099A12.712 12.712 0 0 0 271.035 0ZM318.886 65.262v-9.644h20.778V34.683h-20.778V10.33h-21.501v69.35a24.292 24.292 0 0 0 7.092 17.158 24.193 24.193 0 0 0 17.121 7.11h16.715l8.681-21.126c-27.126 0-27.403 1.984-28.108-17.559ZM53.748 44.659c-5.935-7.046-14.843-9.867-22.498-9.867C13.123 34.792 0 49.666 0 70.486c0 20.984 13.124 35.7 31.25 35.684 7.812 0 17.968-3.262 23.746-11.416l.63 11.182h22.34V4.512H53.748V44.66Zm-14.377 44.31c-8.594 0-15.153-6.73-15.153-18.483s6.56-18.483 15.153-18.483c8.594 0 15.158 6.741 15.158 18.483 0 11.743-6.564 18.484-15.158 18.484ZM357.475 11.52a2.068 2.068 0 0 0 .646-1.686 2.25 2.25 0 0 0-.732-1.837 3.496 3.496 0 0 0-2.252-.604h-3.293v8.53h1.34v-3.67h1.405l2.322 3.67h1.422l-2.436-3.768a2.875 2.875 0 0 0 1.578-.636Zm-2.924-.338h-1.367V8.464h1.758c.225 0 .45.017.672.05.21.028.413.09.603.184.174.091.321.226.428.391.121.208.179.446.168.685.016.276-.05.55-.189.789a1.09 1.09 0 0 1-.494.424c-.226.094-.466.149-.711.163-.282.021-.564.032-.868.032Z" />
      <path d="M359.835 6.453a7.456 7.456 0 0 0-2.311-1.533 7.212 7.212 0 0 0-2.821-.544 7.315 7.315 0 0 0-2.837.544c-.869.36-1.662.88-2.339 1.533a7.305 7.305 0 0 0-1.589 2.305 7.031 7.031 0 0 0-.586 2.87 7.179 7.179 0 0 0 .586 2.92 7.406 7.406 0 0 0 1.578 2.304 7.162 7.162 0 0 0 2.339 1.533c.901.366 1.865.55 2.837.544a7.302 7.302 0 0 0 2.821-.544 7.079 7.079 0 0 0 2.322-1.533 7.46 7.46 0 0 0 1.579-2.326 7.196 7.196 0 0 0 .586-2.92 7.031 7.031 0 0 0-.586-2.87 7.37 7.37 0 0 0-1.579-2.283Zm.402 7.681a6.043 6.043 0 0 1-1.27 1.985 5.86 5.86 0 0 1-4.253 1.794 5.888 5.888 0 0 1-2.387-.479 5.75 5.75 0 0 1-1.91-1.316 6.029 6.029 0 0 1-1.291-1.984 6.666 6.666 0 0 1-.456-2.49 6.474 6.474 0 0 1 .456-2.44c.289-.732.721-1.4 1.269-1.963a5.804 5.804 0 0 1 1.91-1.305 5.888 5.888 0 0 1 2.387-.478 5.775 5.775 0 0 1 2.355.478 5.876 5.876 0 0 1 1.899 1.305 5.959 5.959 0 0 1 1.291 1.946c.307.777.462 1.606.456 2.441a6.69 6.69 0 0 1-.456 2.506Z" />
    </g>
    <defs>
      <clipPath id="doqitLogo_svg__a">
        <path fill="#fff" d="M0 0h362v141H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDoqitLogo;
