import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { TdInputProp } from './types';

export const TdInput = ({
  label, name, placeholder, type, id, register, error, errorMessage, width, isIcon = false, value, handleInputOnChange,
}: TdInputProp) => {
  return (
    <label htmlFor={id} className="relative text-sm text-gray-700">
      {label}
      <div className="mt-1 relative">
        {isIcon && (
          <div className="mt-[15px]">
            <CalendarIcon className="pointer-events-none w-8 h-8 absolute transform -translate-y-[8%] left-3 mt-[15px]" />
          </div>
        )}
        <input
          id={id}
          type={type}
          {...register(name)}
          placeholder={placeholder}
          className={`block ${width ? width : 'w-full'} h-14 appearance-none rounded-xl 
          border border-gray-300 ${isIcon ? 'px-12' : 'px-3'} py-2 placeholder-gray-400 shadow-sm outline-doqit-primary
          focus:outline-none sm:text-sm ${error ? 'border border-red-400' : 'bg-input-bg'}`}
          value={value}
          onChange={(e) => handleInputOnChange && handleInputOnChange(e.target.value)}
        />
        { error && (
          <p className="text-error text-sm mt-2 flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span className={`text-sm pl-1 ${name}-error`}>{errorMessage && errorMessage}</span>
          </p>
        )}
      </div>
    </label>
  );
};

export default TdInput;
