/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgSubscriptions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#subscriptions_svg__a)" />
    <circle cx={30} cy={30} r={30} fill="url(#subscriptions_svg__b)" />
    <g clipPath="url(#subscriptions_svg__c)">
      <path
        d="M24 16c1.106 0 2 .782 2 1.75v1.75h8v-1.75c0-.968.894-1.75 2-1.75 1.106 0 2 .782 2 1.75v1.75h3c1.656 0 3 1.176 3 2.625v2.625H16v-2.625c0-1.45 1.344-2.625 3-2.625h3v-1.75c0-.968.894-1.75 2-1.75Zm-8 10.5h28v14.875C44 42.825 42.656 44 41 44H19c-1.656 0-3-1.176-3-2.625V26.5Z"
        fill="#fff"
      />
      <path
        d="M33.5 40.291a.213.213 0 0 1-.216.209H25.9a.213.213 0 0 1-.216-.209v-1.004a.21.21 0 0 1 .165-.203c1.186-.285 1.963-1.278 1.963-2.457 0-.384-.067-.767-.159-1.153h-1.812a.213.213 0 0 1-.216-.209v-.782c0-.115.097-.21.216-.21h1.448c-.21-.654-.394-1.322-.394-2.01 0-1.977 1.58-3.138 4.086-3.138.715 0 1.386.143 1.896.331.084.032.14.11.14.196v1.03a.203.203 0 0 1-.09.17.22.22 0 0 1-.196.028 5.076 5.076 0 0 0-1.629-.273c-1.437 0-2.354.693-2.354 1.83 0 .645.167 1.25.361 1.84h3.021c.119 0 .216.093.216.208v.783a.213.213 0 0 1-.216.208h-2.66c.084.345.144.702.144 1.07 0 1.061-.445 1.927-1.184 2.376v.123h4.854c.119 0 .216.093.216.208v1.038Z"
        fill="#BE48B6"
      />
    </g>
    <defs>
      <linearGradient
        id="subscriptions_svg__a"
        x1={7}
        y1={9.5}
        x2={47.5}
        y2={53.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#28AAB7" />
        <stop offset={1} stopColor="#14BEBC" />
      </linearGradient>
      <linearGradient
        id="subscriptions_svg__b"
        x1={8}
        y1={8.5}
        x2={45.5}
        y2={55.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF44C0" />
        <stop offset={1} stopColor="#D34EA6" />
      </linearGradient>
      <clipPath id="subscriptions_svg__c">
        <path fill="#fff" transform="translate(16 16)" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSubscriptions;
