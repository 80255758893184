export type OtpField = {
  [key: string]: number;
  firstOTP: number;
  secondOTP: number;
  thirdOTP: number;
  fourthOTP: number;
};

// eslint-disable-next-line no-shadow
export enum StatusSteps {
  current,
  complete,
  pending,
}
