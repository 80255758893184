import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { worker } from 'common/mockServiceWorker/worker';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/naming-convention
const { REACT_APP_SHOULD_RUN_MSW } = runtimeEnv();

if (REACT_APP_SHOULD_RUN_MSW) {
  worker.start({ onUnhandledRequest: 'bypass' });
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
