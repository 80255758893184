import {
  PdfIcon, DocIcon, XlsIcon, PptIcon, ZipIcon, VideoIcon,
} from 'assets/images';

export const repeatDropDownList = [
  {
    name: 'Monthly',
    id: 1,
  },
  {
    name: 'Annually',
    id: 2,
  },
];

export const beforeDropDownList = [
  {
    name: 'Days',
    id: 1,
  },
  {
    name: 'Months',
    id: 2,
  },
];

interface ExtensionMap {
  [key: string] : string
}

export const allowedDocumentType: ExtensionMap = {
  docx: DocIcon,
  doc: DocIcon,
  xls: XlsIcon,
  csv: XlsIcon,
  xlsx: XlsIcon,
  xlsm: XlsIcon,
  pdf: PdfIcon,
  ppt: PptIcon,
  pptx: PptIcon,
  potx: PptIcon,
  dotx: DocIcon,
  txt: DocIcon,
  zip: ZipIcon,
  pbix: ZipIcon,
  mp4: VideoIcon,
  mov: VideoIcon,
};

export const supportedDocumentType: string[] = [
  'jpeg',
  'jpg',
  'png',
  'docx',
  'doc',
  'xls',
  'csv',
  'xlsx',
  'xlsx',
  'xlsm',
  'pdf',
  'ppt',
  'pptx',
  'potx',
  'dotx',
  'txt',
  'zip',
  'pbix',
  'mp4',
  'mov',
];
