import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthState, Token, User, Persist,
} from 'features/login/types';
import { PURGE } from 'redux-persist';
import type { RootState } from 'common/store/store';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null, token: null, persist: null, documentCount: null,
  } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          user, token, persist,
        },
      }: PayloadAction<{ user: User | null; token: Token | null; persist: Persist | null, }>,
    ) => {
      state.user = user;
      state.token = token;
      state.persist = persist;
    },
    clearCredentials: (state) => {
      state.user = authSlice.getInitialState().user;
      state.token = authSlice.getInitialState().token;
      state.persist = authSlice.getInitialState().persist;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.user = authSlice.getInitialState().user;
      state.token = authSlice.getInitialState().token;
      state.persist = authSlice.getInitialState().persist;
    });
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
