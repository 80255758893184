import PropTypes from 'prop-types';
import { AccordionItem as Item } from '@szhsin/react-accordion';
import { Chevron } from 'assets/images';

export const AccordionItem = ({ header, ...rest }) => {
  return (
    <Item
      {...rest}
      // eslint-disable-next-line react/no-unstable-nested-components
      header={({ state: { isEnter } }) => (
        <>
          {header}
          <img
            className={`ml-auto transition-transform duration-200 ease-in-out ${isEnter && 'rotate-180'}`}
            src={Chevron}
            alt="Chevron Down"
          />
        </>
      )}
      className="border-b rounded-lg bg-select mb-3"
      buttonProps={{
        className: () => 'flex w-full p-2.5 text-left',
      }}
      contentProps={{
        className: 'transition-height duration-200 ease-in-out',
      }}
      panelProps={{ className: 'p-2.5' }}
    />
  );
};

AccordionItem.propTypes = {
  header: PropTypes.string,
};

export default AccordionItem;
