import * as React from 'react';
import { SVGProps } from 'react';
const SvgOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={24} rx={12} fill="#180064" />
    <circle cx={36} cy={12} r={6} fill="#fff" />
    <path
      d="M21 9.42 19.59 8 13 14.59l-2.58-2.57L9 13.43l4 3.99 8-8Z"
      fill="#fff"
    />
  </svg>
);
export default SvgOn;
