import * as React from 'react';
const SvgSearchBarIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="search"
    className="w-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
  >
    <path
      d="M10.8866 3.46725C12.3307 3.46593 13.7428
      3.89295 14.9441 4.69428C16.1455 5.49561 17.0822
      6.63526 17.6357 7.96904C18.1893 9.30283 18.3348
      10.7708 18.0539 12.1873C17.7729 13.6038 17.0782
      14.9052 16.0575 15.9268C15.0369 16.9484 13.7362
      17.6443 12.3199 17.9265C10.9037 18.2087 9.43555
      18.0645 8.10126 17.5122C6.76697 16.9599 5.62647
      16.0242 4.82404 14.8236C4.02161 13.623 3.59331
      12.2113 3.59331 10.7673C3.60206 8.83502 4.37307
      6.98433 5.73875 5.6174C7.10443 4.25047 8.95442
      3.47777 10.8866 3.46725ZM10.8866 2.10059C9.17254
      2.10059 7.49693 2.60888 6.0717 3.56118C4.64647
      4.51349 3.53565 5.86704 2.87969 7.45066C2.22373
      9.03429 2.0521 10.7769 2.3865 12.458C2.72091
      14.1392 3.54633 15.6835 4.75838 16.8955C5.97044
      18.1076 7.51469 18.933 9.19586 19.2674C10.877
      19.6018 12.6196 19.4302 14.2032 18.7742C15.7869
      18.1182 17.1404 17.0074 18.0927 15.5822C19.045
      14.157 19.5533 12.4814 19.5533 10.7673C19.5533
      8.46871 18.6402 6.26431 17.0149 4.63899C15.3896
      3.01368 13.1852 2.10059 10.8866 2.10059Z"
      fill="currentColor"
    />
    <path
      d="M23.3334 22.2943L18.4201 17.3477L17.4734
      18.2877L22.3867 23.2343C22.4484 23.2965 22.5218
      23.3459 22.6026 23.3797C22.6834 23.4135 22.7701
      23.4311 22.8577 23.4314C22.9453 23.4317 23.0321
      23.4147 23.1131 23.3815C23.1942 23.3483 23.2679
      23.2994 23.3301 23.2377C23.3922 23.1759 23.4416
      23.1026 23.4754 23.0218C23.5092 22.941 23.5268
      22.8543 23.5271 22.7667C23.5274 22.6791 23.5105
      22.5923 23.4772 22.5112C23.444 22.4302 23.3951
      22.3565 23.3334 22.2943Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSearchBarIcon;
