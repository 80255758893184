/* eslint-disable react/button-has-type */
import React from 'react';
import { ButtonProps } from './types';

export const TdButton = ({
  label,
  type = 'submit',
  isDisabled = false,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`block w-full appearance-none rounded-xl justify-center rounded-xl border border-transparent bg-doqit-primary py-4 px-4 text-sm font-semibold
          text-white shadow-sm ${(isDisabled) ? 'disabled:opacity-75' : ''}`}
      disabled={isDisabled}
      data-cy="submit-btn"
    >
      {label}
    </button>
  );
};

export default TdButton;
