/* eslint-disable max-len */
import Spinner from './spinner';
export const Loader = () => {
  return (
    <div className="doqit-loader-container">
      <Spinner />
    </div>
  );
};

export default Loader;
