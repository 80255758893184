import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusSteps } from 'common/utils/types';
import { repeatDropDownList, beforeDropDownList } from 'features/UploadDocument/content';
import {
  User, DefaultUpload, CategoryType, DVLAType,
} from './types';

export type CounterState = {
  header: string;
  user: User;
  uploadDocument: DefaultUpload;
}

const initialUserState: User = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  terms: false,
  token: '',
};

const initialUploadDocumentState: DefaultUpload = {
  stepNameStatus: StatusSteps.current,
  stepRenewalStatus: StatusSteps.pending,
  stepFileStatus: StatusSteps.pending,
  name: '',
  category: [],
  renewalDate: '',
  reminder: true,
  before: '',
  beforeType: beforeDropDownList[0].name,
  repeatToggle: true,
  repeat: repeatDropDownList[1].name,
  files: [],
  categoryList: [],
  dvlaResponse: [],
};

export const initialState: CounterState = {
  header: '',
  user: initialUserState,
  uploadDocument: initialUploadDocumentState,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeader: (state, { payload }: PayloadAction<string>) => {
      state.header = payload;
    },
    setUserData: (
      state,
      { payload: { user } }: PayloadAction<{ user: User }>,
    ) => {
      state.user = { ...state.user, ...user };
    },
    setUserPassword: (
      state,
      { payload: { password } }: PayloadAction<{ password: string }>,
    ) => {
      state.user = { ...state.user, password };
    },
    setCategoryData: (state, { payload: { categoryList } }: PayloadAction<{ categoryList: CategoryType}>) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        categoryList,
      };
    },
    setStepNameUploadData: (
      state,
      { payload }: PayloadAction<DefaultUpload>,
    ) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        name: payload.name,
        category: payload.category,
        stepNameStatus: payload.stepNameStatus,
      };
    },
    setStepVehicleData: (
      state,
      { payload }: PayloadAction<DefaultUpload>,
    ) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        vehicleName: payload.vehicleName,
        vehicleRegistration: payload.vehicleRegistration,
        stepVehicleStatus: payload.stepVehicleStatus,
        dvlaResponse: payload.dvlaResponse,
      };
    },
    setStepRenewalUploadData: (
      state,
      { payload }: PayloadAction<DefaultUpload>,
    ) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        renewalDate: payload.renewalDate,
        reminder: payload.reminder,
        before: payload.before,
        beforeType: payload.beforeType,
        repeatToggle: payload.repeatToggle,
        repeat: payload.repeat,
        stepRenewalStatus: payload.stepRenewalStatus,
      };
    },
    setVehicleRenewalFromDvla: (
      state,
      { payload }: PayloadAction<DVLAType>,
    ) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        renewalDate: payload.renewalDate,
        name: payload.name || '',
        vehicleId: payload.vehicleId,
        motStatus: payload.motStatus,
        taxStatus: payload.taxStatus,
        category: payload.category ? payload.category : [],
        taxDateEditable: payload?.taxDateEditable,
        motDateEditable: payload?.motDateEditable,
      };
    },
    setStepFileUploadData: (
      state,
      { payload }: PayloadAction<DefaultUpload>,
    ) => {
      state.uploadDocument = {
        ...state.uploadDocument,
        files: payload.files,
      };
    },
    resetUploadData: (
      state,
    ) => {
      state.uploadDocument = {
        ...initialUploadDocumentState,
      };
    },
  },
});
export const {
  setUserData,
  setStepNameUploadData,
  setStepVehicleData,
  setStepRenewalUploadData,
  setStepFileUploadData,
  setCategoryData,
  resetUploadData,
  setVehicleRenewalFromDvla,
} = appSlice.actions;

export default appSlice.reducer;
