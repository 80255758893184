import * as React from 'react';
import { SVGProps } from 'react';
const SvgMenuSubtract = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.015 0H0v39h64V0h-.015C63.456 17.21 49.339 31 32 31S.544 17.21.015 0Z"
      fill="#fff"
    />
  </svg>
);
export default SvgMenuSubtract;
