import { categoryIcons } from 'common/constants';

type CategoryIconsProps = {
    categoryName: string,
    categoryIconFallbackUrl: string,
    className?: string,
    alt?: string,
}

export const CategoryIcon = (props: CategoryIconsProps) => {
  const {
    categoryName, categoryIconFallbackUrl, alt = '', ...rest
  } = props;
  if (categoryIcons[categoryName]) {
    const Icon = categoryIcons[categoryName];
    return (
      <Icon
        {...rest}
      />
    );
  }
  return (
    <img
      src={categoryIconFallbackUrl}
      alt={alt || categoryName || ''}
      {...rest}
    />
  );
};

export default CategoryIcon;
