/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgVehicle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#vehicle_svg__a)" />
    <path
      d="m24.13 22.384-1.377 3.83h15.494l-1.377-3.83a1.686 1.686 0 0 0-1.592-1.098h-9.556c-.717 0-1.355.441-1.592 1.098Zm-5.042 4.077 1.857-5.16C21.655 19.325 23.57 18 25.722 18h9.556c2.151 0 4.066 1.325 4.778 3.301l1.856 5.16C43.135 26.954 44 28.129 44 29.5v9.857c0 .909-.754 1.643-1.688 1.643h-1.687c-.933 0-1.688-.734-1.688-1.643v-2.464H22.064v2.464c0 .909-.755 1.643-1.688 1.643h-1.688C17.755 41 17 40.266 17 39.357V29.5c0-1.37.865-2.546 2.088-3.04Zm4.662 4.682c0-.909-.754-1.643-1.688-1.643-.933 0-1.687.734-1.687 1.643s.754 1.643 1.688 1.643c.933 0 1.687-.734 1.687-1.643Zm15.188 1.643c.933 0 1.687-.734 1.687-1.643s-.754-1.643-1.688-1.643c-.933 0-1.687.734-1.687 1.643s.754 1.643 1.688 1.643Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="vehicle_svg__a"
        x1={8}
        y1={8.5}
        x2={45.5}
        y2={55.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADBBEB" />
        <stop offset={1} stopColor="#87A6D5" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgVehicle;
