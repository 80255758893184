/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgProperty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="url(#property_svg__a)" />
    <path
      d="M42.435 31.563c.826 0 1.555-.68 1.555-1.56.049-.438-.146-.826-.534-1.167l-2.567-2.26v-5.882c0-.86-.695-1.556-1.556-1.556h-1.555c-.86 0-1.556.695-1.556 1.556v1.784l-5.177-4.555c-.292-.243-.68-.34-1.02-.34-.341 0-.73.048-1.07.389L16.486 28.836c-.34.34-.486.73-.486 1.167 0 .875.68 1.56 1.556 1.56h1.555v3.389c-.005.043-.005.087-.005.136v5.44c0 1.074.87 1.944 1.945 1.944h.777c.059 0 .117-.005.175-.01.073.005.146.01.22.01h2.721c1.075 0 1.945-.87 1.945-1.945V36.25c0-.86.695-1.555 1.555-1.555h3.112c.86 0 1.555.695 1.555 1.555v4.278c0 1.075.87 1.945 1.945 1.945h2.746c.068 0 .136 0 .204-.005.054.005.107.005.16.005h.778c1.075 0 1.945-.87 1.945-1.945v-.787a3.42 3.42 0 0 0 .024-.394l-.034-7.787h1.556v.004Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="property_svg__a"
        x1={7}
        y1={10.5}
        x2={45.5}
        y2={54}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7E1FE" />
        <stop offset={1} stopColor="#5DCFED" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgProperty;
