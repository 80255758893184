import React from 'react';
import { InfoErrorIcon } from 'common/designSystem/iconComponents';

export const FieldError = ({ errorMessage }: { errorMessage: string}) => {
  return (
    <p className="text-error text-sm mt-2 flex flex-row">
      <InfoErrorIcon />
      <span className="text-sm pl-1" data-cy="file-error">{errorMessage && errorMessage}</span>
    </p>
  );
};
export default FieldError;
