import React from 'react';
import { ButtonProps } from './types';
export const LinkButton = ({
  text, onClickHandler, isFilled = false, isDisabled = false, selector = 'cta-skip',
}: ButtonProps) => {
  return (
    <button
      className={`background-transparent font-bold uppercase outline-none focus:outline-none ${(isDisabled) ? 'opacity-60' : ''}
      mr-1 ease-linear transition-all duration-150 ${(isFilled) ? 'w-full py-4 px-4 bg-doqit-primary rounded-xl text-white' : 'text-doqit-primary'}`}
      type="button"
      onClick={onClickHandler}
      data-cy={selector}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default LinkButton;
