/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const SvgTax = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30.5} cy={30.101} r={30} fill="url(#tax_svg__a)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.786 17.1c-.872 0-1.707.343-2.324.952a3.232 3.232 0 0 0-.962 2.299V43.1l5.75-3.25L31 43.1l5.75-3.25 5.75 3.25V20.35c0-.862-.346-1.689-.962-2.299a3.304 3.304 0 0 0-2.324-.951H22.786Zm4.107 4.876c-.654 0-1.28.256-1.743.713a2.424 2.424 0 0 0-.721 1.724c0 .647.26 1.267.721 1.724a2.478 2.478 0 0 0 1.743.714c.653 0 1.28-.257 1.742-.714a2.424 2.424 0 0 0 .722-1.724c0-.646-.26-1.266-.722-1.724a2.478 2.478 0 0 0-1.742-.713Zm10.197.476a1.652 1.652 0 0 0-2.323 0l-9.857 9.75a1.624 1.624 0 0 0-.502 1.154 1.611 1.611 0 0 0 .482 1.164 1.643 1.643 0 0 0 1.175.475 1.658 1.658 0 0 0 1.168-.495l9.857-9.75a1.616 1.616 0 0 0 0-2.298ZM35.107 30.1c-.653 0-1.28.256-1.742.713a2.424 2.424 0 0 0-.722 1.724c0 .647.26 1.267.722 1.724a2.478 2.478 0 0 0 1.742.714c.654 0 1.28-.257 1.743-.714a2.424 2.424 0 0 0 .721-1.724c0-.646-.26-1.266-.721-1.723a2.478 2.478 0 0 0-1.743-.714Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="tax_svg__a"
        x1={8.5}
        y1={8.601}
        x2={46}
        y2={55.601}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1C12E" />
        <stop offset={1} stopColor="#B4AE25" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgTax;
