import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

export const Modal = ({
  heading, subHeading, isOpen, onCloseHandler, isForgotPassword,
}: { heading: string, subHeading?: string, isOpen: boolean, onCloseHandler?: () => void; isForgotPassword?:boolean }) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen} onClick={onCloseHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex mt-60 justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left
                shadow-xl transition-all w-64 h-60"
              >
                <div>
                  <div className={`${isForgotPassword ? '' : 'dialog-done-panel-text'}  mx-auto flex h-12 w-12 items-center justify-center rounded-full`}>
                    <CheckIcon className="h-12 w-12 text-green-600 font-semibold" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 my-5 text-center">
                      {heading}
                    </Dialog.Title>
                    <div className="mt-2 w-52 pt-4">
                      <p className="text-sm text-black-500">
                        {subHeading}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
